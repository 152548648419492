import * as React from "react"
import { HeaderNavLinkProps } from "./types"
import ProtectedNode from "../ProtectedNode"
import HeaderNavButton from "./HeaderNavButton"

function HeaderNavLink(props: HeaderNavLinkProps) {
  const { route } = props

  return (
    <ProtectedNode withTooltip resource={route.resource} action="view">
      <HeaderNavButton route={route} />
    </ProtectedNode>
  )
}

export default HeaderNavLink
