import React from "react"
import { PlusIcon } from "kui-icon"
import { Button } from "kui-basic"
import { useToggle } from "kui-utils"
import styled from "@emotion/styled"
import { TwoColumnsButtonProps } from "./types"
import LinkButton from "../LinkButton"

const LinkOrAddButton = (props: TwoColumnsButtonProps) => {
  const { label, link, isDisplayed, renderModal, onClick } = props
  const [open, handleOpen, handleClose] = useToggle()

  const handleClick = (e: any) => {
    e.stopPropagation()
    if (onClick) onClick()
    handleOpen()
  }

  const handleWrapperClick = (e: any) => {
    e.stopPropagation()
  }

  if (!isDisplayed) return null

  return (
    <StyledWrapper onClick={handleWrapperClick}>
      {link ? (
        <LinkButton fullWidth href={link}>
          {label}
        </LinkButton>
      ) : (
        <Button
          fullWidth
          size="xs"
          onClick={handleClick}
          endIcon={<PlusIcon />}
        >
          {label}
        </Button>
      )}
      {renderModal && renderModal({ open, handleClose })}
    </StyledWrapper>
  )
}

export default LinkOrAddButton

const StyledWrapper = styled.div`
  width: 200px;
`
