import { Routes } from "../types/route"

export const headerRoutes = [
  {
    title: "Dashboard",
    path: Routes.main,
  },
  {
    title: "Clients",
    path: Routes.clients,
  },
  {
    title: "Objects",
    path: Routes.apartments,
  },
  {
    title: "Companies",
    path: Routes.companies,
  },
  {
    title: "Contracts",
    path: Routes.contracts,
  },
  {
    title: "Functions",
    path: Routes.functions,
  },
]
