import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import {
  Loader,
  Paginator,
  SortingFilter,
  MultistepForm,
  toNumber,
} from "kui-utils"
import { RentalContractModel } from "kui-crm"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import RentalContractStore from "./RentalContractStore"
import {
  clearNotValidFields,
  transformToSnakeCase,
} from "../../../utils/service/mapper"
import RentalContractsAgent from "../../../agent/RentalContracts"
import {
  FetchRentalContracts,
  PatchRentalContractParams,
} from "../../../types/api/rental_contract"
import { RentalContractCreationFields } from "../forms/CreateRentalContractForm/types"
import { ClientAutocompleteFields } from "../../../components/entityInputs/ClientAutocomplete/types"

const RCFormStages = ["apartment", "info", "common_terms"] as const

class RentalContractsStore implements MultipleEntitiesStoreInterface {
  contracts: RentalContractStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<RentalContractCreationFields, typeof RCFormStages>

  constructor() {
    this.contracts = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("-id")
    this.creationForm = new MultistepForm<
      RentalContractCreationFields,
      typeof RCFormStages
    >(null, RCFormStages)
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<FetchRentalContracts>(
      RentalContractsAgent.all(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    runInAction(() => {
      if (!err && res) {
        const mapper = (contract: RentalContractModel) =>
          new RentalContractStore(contract)

        this.contracts = this.paginator.getPageResponse<
          RentalContractModel,
          RentalContractStore
        >(res, this.contracts, mapper)
      } else {
        this.loader.setError("fetch rental contracts", err)
      }
      this.loader.endLoading()
    })
  }

  createRentalContract = async (data: RentalContractCreationFields) => {
    this.creationLoader.startLoading("creation rental contract")

    const body: PatchRentalContractParams =
      RentalContractsStore.getRequestBodyForCreationRC(data)

    const [err, res] = await to(RentalContractsAgent.create(body))
    runInAction(() => {
      if (!err && res) {
        this.contracts = [...this.contracts, new RentalContractStore(res)]
      } else {
        this.creationLoader.setError("creation rental contract", err)
      }
      this.creationLoader.endLoading()
    })

    return res?.id
  }

  static getRequestBodyForCreationRC = (data: RentalContractCreationFields) =>
    clearNotValidFields({
      ...transformToSnakeCase(data),
      apartment_id: data.apartment.id,
      day_of_payment: data.paymentDate,
      month_payment: toNumber(data.rentalPrice),
      renter_id: data.tenant.id,
      start_date: data.startDate.toISODate(),
      sign_date: data.signDate.toISODate(),
      end_date: data.endDate?.toISODate(),
      auto_prolongate: data.autoProlongation,
      termination_penalty: data.penalty,
      termination_showing_days: data.showing,
      roommates_ids:
        data.roommates?.map(
          (roommate: ClientAutocompleteFields) => roommate.id!
        ) || [],
    }) as PatchRentalContractParams
}

export default RentalContractsStore
