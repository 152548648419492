import React, { useState } from "react"
import { observer } from "mobx-react"
import _ from "lodash"
import MultistepForm from "../../../../../common/MultistepForm"
import ImpairmentInfoStep from "./ImpairmentInfoStep"
import ImpairmentImagesStep from "./ImpairmentImagesStep"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import { ImpairmentsInspectionFormFields } from "./types"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import AddImpairmentStep from "./AddImpairmentStep"
import ImpairmentConfirmStep from "./ImpairmentConfirmStep"

const getInitialState = (length?: number) => {
  if (!length) return []
  return _.range(length)
}

const ImpairmentsInspectionForm = (props: InspectionFormProps) => {
  const { handleCreate, shift, handleClose } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const [impairments, setImpairments] = useState<number[]>(
    getInitialState(formStore.fields?.impairments?.length)
  )

  const addImpairment = () => {
    setImpairments((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const removeImpairment = () => {
    setImpairments((prev) => prev.slice(0, prev.length - 1))
  }

  const handleSubmit = async () => {
    handleClose()
    const inspectionId = await inspectionsStore.createImpairmentsInspection(
      formStore.fields as ImpairmentsInspectionFormFields
    )
    if (inspectionId) handleCreate(inspectionId)
  }

  return (
    <MultistepForm stepShift={shift} form={formStore}>
      <AddImpairmentStep addStep={addImpairment} handleSubmit={handleSubmit} />
      {impairments.map((impairment) => [
        <ImpairmentInfoStep
          key={`info_${impairment}`}
          index={impairment}
          removeImpairment={removeImpairment}
        />,
        <ImpairmentImagesStep
          required
          key={`images_${impairment}`}
          index={impairment}
          addStep={addImpairment}
        />,
      ])}
      <ImpairmentConfirmStep handleSubmit={handleSubmit} />
    </MultistepForm>
  )
}

export default observer(ImpairmentsInspectionForm)
