import React from "react"
import { observer } from "mobx-react"
import { Tooltip } from "kui-crm"
import { Caption } from "kui-basic"
import useStore from "../../../hooks/useStore"
import { ProtectedNodeProps } from "./types"
import { noAccessMessage } from "../../../utils/content/constants"

const ProtectedNode = (props: ProtectedNodeProps) => {
  const { withTooltip, resource, action, children, className, strategy } = props
  const { cabinetStore } = useStore()
  const hasAccess = resource
    ? cabinetStore.isThereAccess(resource, action)
    : true

  if (hasAccess) return <>{children}</>

  if (withTooltip)
    return (
      <Tooltip
        placement="bottom"
        className={className}
        strategy={strategy}
        content={
          <Caption
            data-testid="tooltipMessage"
            size="xs"
            weight={500}
            color="fiftyP"
          >
            {noAccessMessage}
          </Caption>
        }
      >
        {children ? React.cloneElement(children, { disabled: true }) : null}
      </Tooltip>
    )

  return null
}

export default observer(ProtectedNode)
