export const dashboardLinksFields = [
  { name: "newContractsCount", label: "New rental contracts count" },
  { name: "newContractsRent", label: "Total rent" },
  { name: "newContractsCommission", label: "Commission fees" },
]

export const dashboardCardsFields = [
  { name: "newContractsList", label: "New rental contracts list" },
  { name: "objectsNeedTenant", label: "Objects need tenant" },
  {
    name: "objectsNeedInitialList",
    label: "Objects need initial inspection list",
  },
  {
    name: "objectsNeedInitialMap",
    label: "Objects need initial inspection map",
  },
  { name: "closingRentalContracts", label: "Closing rental contracts" },
  { name: "pendingRentalContracts", label: "Pending rental contracts" },
]
