import React from "react"
import { observer } from "mobx-react"
import { Navigate } from "react-router-dom"
import { ProtectedRouteProps } from "./types"
import useStore from "../../../hooks/useStore"
import NoAccessPage from "../NoAccessPage"

const ProtectedRouteContent = (props: ProtectedRouteProps) => {
  const { route } = props
  const { content, resource } = route
  const { cabinetStore, authStore } = useStore()
  const haveAccess = resource
    ? cabinetStore.isThereAccess(resource, "view")
    : true

  const routeContent = haveAccess ? content : <NoAccessPage />

  return (
    <>{authStore.isAuthed ? routeContent : <Navigate to="/signin" replace />}</>
  )
}

export default observer(ProtectedRouteContent)
