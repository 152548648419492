import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { CabinetUserModel } from "../../types/api/cabinet"
import CabinetAgent from "../../agent/Cabinet"
import RootStore from "../Root"
import {
  AccessPermission,
  AccessPermissionActions,
  AccessPermissionResources,
} from "../../types/store/appStore"

class CabinetStore {
  rootStore: RootStore

  user: CabinetUserModel | null

  accessPermissions: AccessPermission

  loader: Loader

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.user = null
    this.accessPermissions = { apartment: ["view"] }
    this.loader = new Loader()
    makeAutoObservable(this, { rootStore: false })
  }

  getUser = async () => {
    this.loader.startLoading()
    const [err, res] = await to(CabinetAgent.getUser())
    runInAction(() => {
      if (!err && res) {
        this.user = res
      } else {
        this.loader.setError("get user info", err)
      }
    })
    this.loader.endLoading()
  }

  fetchAccessPermissions = async () => {
    this.accessPermissions = { apartment: ["view", "edit"] }
  }

  updateAccessPermissions = (data: AccessPermission) => {
    this.accessPermissions = data
  }

  isThereAccess = (
    resource: AccessPermissionResources,
    action: AccessPermissionActions
  ) => {
    const resourcePermissions = this.accessPermissions[resource]
    if (!resourcePermissions) return false

    return !!resourcePermissions.find((permission) => permission === action)
  }

  get fullName() {
    return this.user?.first_name || this.user?.last_name
      ? `${this.user?.first_name ?? ""} ${this.user?.last_name ?? ""}`
      : this.user?.username || ""
  }

  get role() {
    return this.user?.user_role
  }

  reset = () => {
    this.accessPermissions = {}
  }
}

export default CabinetStore
