import { FormWrapper } from "kui-crm"
import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Caption, Grid } from "kui-basic"
import { WarningIcon } from "kui-icon"
import styled from "@emotion/styled"
import InputWithDatePicker from "../../../../components/ui/InputWithDatepicker"
import TariffGroupReplaceFormSchema from "./schema"
import { TariffGroupReplaceFormProps } from "./types"
import useMeterStore from "../../store"

const TariffGroupReplaceForm = (props: TariffGroupReplaceFormProps) => {
  const { handleSubmit } = props
  const { meterInfoStore } = useMeterStore()
  const form = useForm({
    resolver: yupResolver(TariffGroupReplaceFormSchema),
  })

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Start editing">
      {meterInfoStore.resource === "water" && (
        <Box mb={2}>
          <Grid container alignItems="center" wrap="nowrap">
            <StyledIcon />
            <Caption size="xs" weight={500} color="fiftyP">
              You are making changes to the tariff group, the changes will be
              displayed in all counters using this tariff group
            </Caption>
          </Grid>
        </Box>
      )}
      <InputWithDatePicker
        form={form}
        label="Date of change"
        name="changeDate"
      />
    </FormWrapper>
  )
}

export default TariffGroupReplaceForm

const StyledIcon = styled(WarningIcon)`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP};
  }
`
