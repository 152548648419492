import React from "react"
import { Box, Button, Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { useNavigate } from "react-router-dom"
import { PrevArrowIcon } from "kui-icon"
import Page from "../Page"
import NoAccessBunnyIcon from "../../icons/NoAccessBunnyIcon"

const NoAccessPage = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <Page withContainer={false}>
      <StyledWrapper
        container
        justify="center"
        alignItems="center"
        direction="column"
        data-testid="noAccessPage"
      >
        <NoAccessBunnyIcon />
        <Box mt={3}>
          <Heading size="h2" color="fiftyP">
            You do not have access to this section 🔒
          </Heading>
        </Box>
        <Box mt={3}>
          <StyledButton
            startIcon={<PrevArrowIcon />}
            size="s"
            variant="whiteWithGray"
            onClick={goBack}
          >
            GO BACK
          </StyledButton>
        </Box>
      </StyledWrapper>
    </Page>
  )
}

export default NoAccessPage

const StyledWrapper = styled(Grid)`
  flex-grow: 1;
  background: ${({ theme }) => theme.palette.background.light1};
`

const StyledButton = styled(Button)`
  border-color: ${({ theme }) => theme.palette.grey.thirty};
`
