import { addToArrayByCondition } from "kui-utils"
import React from "react"
import { HomeIcon, RubleIcon } from "kui-icon"
import { LinkCardProps } from "../../../components/common/DashboardLinkCard/types"
import { Routes } from "../../../../../types/route"
import ConsultantDashboardStore from "../../../store/variants/ConsultantDashboardStore"
import { DashboardNumberCardProps } from "../../../components/common/DasboardNumberCard/types"
import NewContractsCard from "../../../components/cards/NewContractsCard"
import ObjectsNeedTenant from "../../../components/cards/ObjectsNeedTenantCard"
import ObjectsNeedInitialCard from "../../../components/cards/ObjectsNeedInitialCard"
import ObjectsNeedInitialMap from "../../../components/cards/ObjectsNeedInitialMap"
import ClosingContractsCard from "../../../components/cards/ClosingContractsCard"
import PendingContractsCard from "../../../components/cards/PendingContractsCard"

export const getLinkCards = (
  consultantDashboard: ConsultantDashboardStore
): LinkCardProps[] => [
  ...addToArrayByCondition(consultantDashboard.newContractsCount !== null, {
    label: "New rental contracts",
    value: consultantDashboard.newContractsCount,
    icon: <HomeIcon />,
    link: `${Routes.rental_contracts}?sort=-id`,
    compareVariant: "less",
  }),
]

export const getNumberCards = (
  consultantDashboard: ConsultantDashboardStore
): DashboardNumberCardProps[] => [
  ...addToArrayByCondition(consultantDashboard.newContractsRent !== null, {
    label: "Total rent",
    value: consultantDashboard.newContractsRent,
    icon: <RubleIcon />,
    unit: "₽",
  }),
  ...addToArrayByCondition(
    consultantDashboard.newContractsCommission !== null,
    {
      label: "Commission fees",
      value: consultantDashboard.newContractsCommission,
      icon: <RubleIcon />,
      unit: "₽",
    }
  ),
]

export const getListCards = (consultantDashboard: ConsultantDashboardStore) => [
  ...addToArrayByCondition(
    consultantDashboard.newContractsList.value !== null,
    {
      key: "newContractsList",
      card: (
        <NewContractsCard
          contractsStore={consultantDashboard.newContractsList}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    consultantDashboard.objectsNeedTenant.value !== null,
    {
      key: "objectsNeedTenant",
      card: (
        <ObjectsNeedTenant
          objectsStore={consultantDashboard.objectsNeedTenant}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    consultantDashboard.closingRentalContracts.value !== null,
    {
      key: "closingRentalContracts",
      card: (
        <ClosingContractsCard
          contractsStore={consultantDashboard.closingRentalContracts}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    consultantDashboard.pendingRentalContracts.value !== null,
    {
      key: "pendingRentalContracts",
      card: (
        <PendingContractsCard
          contractsStore={consultantDashboard.pendingRentalContracts}
        />
      ),
    }
  ),
]

export const getMapCards = (consultantDashboard: ConsultantDashboardStore) => [
  ...addToArrayByCondition(
    consultantDashboard.settings?.objectsNeedInitialList &&
      consultantDashboard.objectsNeedInitial.value !== null,
    {
      key: "objectsNeedInitialList",
      card: (
        <ObjectsNeedInitialCard
          objectsStore={consultantDashboard.objectsNeedInitial}
        />
      ),
      size: 4,
    }
  ),
  ...addToArrayByCondition(
    consultantDashboard.settings?.objectsNeedInitialMap &&
      consultantDashboard.objectsNeedInitial.value !== null,
    {
      key: "objectsNeedInitialMap",
      card: (
        <ObjectsNeedInitialMap
          objects={consultantDashboard.objectsNeedInitial.objects}
        />
      ),
      size: 8,
    }
  ),
]

export const getConsultantCards = (
  consultantDashboard: ConsultantDashboardStore
) => ({
  linkCards: getLinkCards(consultantDashboard),
  numberCards: getNumberCards(consultantDashboard),
  listCards: getListCards(consultantDashboard),
})
