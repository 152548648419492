import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { TransferInspectionFormFields } from "kui-crm_actions"
import MultistepForm from "../../../../../common/MultistepForm"
import renderImagesSteps from "../../InspectionImagesStep/imagesSteps/renderSteps"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import Loading from "../../../../../common/Loading"
import renderPartialMeterSteps from "../../InspectionMetersStep/PartialMeterInfoStep/renderPartialMeterSteps"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import InspectionFillingStep from "../../InspectionFillingStep"

const TransferInspectionForm = (props: InspectionFormProps) => {
  const { handleCreate, shift, handleClose } = props
  const { inspectionsStore, metersStore, descriptionStore, fillingStore } =
    useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm

  const handleSubmit = async () => {
    handleClose()
    const inspectionId = await inspectionsStore.createTransferInspection(
      formStore.fields as TransferInspectionFormFields
    )
    handleCreate(inspectionId)
  }

  return (
    <>
      <MultistepForm stepShift={shift} form={formStore}>
        {metersStore.loader.isLoading && <Loading height={200} />}
        {renderPartialMeterSteps(formStore.fields?.meters || [])}
        {fillingStore.loader.isLoading && <Loading height={200} />}
        <InspectionFillingStep />
        {renderImagesSteps("transfer", handleSubmit, descriptionStore.fields)}
      </MultistepForm>
      <LoaderState loader={metersStore.loader} onlyError />
    </>
  )
}

export default observer(TransferInspectionForm)
